import _ from 'lodash'
import Utils from '../../common/utils.js';
import axios from '../../common/axios.js'

export default {
	mixins: [Utils],
	data() {
		return {
			edition: null,
			value: null,
			max_show: 3,
			erreur: false,
			voir_plus: false
		}
	},
	props: ['element'],
	created(){
		this.value = _.cloneDeep(this.element.value)
	},
	mounted(){
		this.value = _.cloneDeep(this.element.value)
	},
	methods: {//AS--> Switch une variable d'activité de type boolean
		changeData(){
			let self = this;
			let value = this.value

			//AS--> Valeurs booléennes 
			if(self.edition.format == 'boolean'){
				value = parseInt(value);

			//AS--> Valeurs numériques
			}else{
				if(self.edition.format == 'numeric'){

					//AS--> Vérifie que c'est un chiffre
					if(typeof value != 'number'){
						if(value !== null){
							value = value.replaceAll(',', '.');
							value = value.replaceAll(' ', '');
						}
						if(value !== null && !value.match(/^[0-9.]*$/g)){
							this.erreur = 'numeric_value_only';
							return;
						}
					}

				}

				//AS--> Si la valeur est vide, on vérifie s'il y avait une valeur avant ou non
				if(self.edition.can_empty === false && (value == null || value.trim() == '')){
					let old = null
					this.element.variables.forEach(function(variable){
						if(variable.field == self.edition.field) old = _.clone(variable.value)
					})

					if((self.edition.format == 'numeric' && old != null) || (self.edition.format == 'text' && old != null && old.trim() != '')){
						self.erreur = 'saisir_valeur';
						return;
					}

				}

			}

			//AS--> Modifie la valeur dans l'élément de base
			let element = this.element
			element.variables.forEach(function(variable){
				if(variable.field == self.edition.field) variable.value = value
			})
			this.element = element

			this.postInexFieldData(this.edition.field, value)

			this.edition = null
			this.value   = null
			this.erreur  = null
		},
		//AS--> Ouvre l'éditeur
		editAndFocus(variable){
			this.erreur  = false
			this.edition = _.cloneDeep(variable)
			this.value   = this.edition.value

			setTimeout(
			  function(){
			    let input = document.getElementById('edition_'+variable.field);
			    if(input) input.focus();
			  },
			  20
			)
		},
	},
	computed:{

	},
	components:{
		
	}
}