import _ from 'lodash'
import Utils from '../utils.js';
import axios from '../axios.js'
import ContentEditable from '../../common/forms/ContentEditable.vue'

export default {
	mixins: [Utils],
	data() {
		return {
			contacts: [],
			company: this.$store.state.company.company,
			edition: false,
			civilite_edition: null,
			erreurs: {}
		}
	},
	props: ['element'],
	mounted(){
		this.contacts = _.cloneDeep(this.element.list)
	},
	methods:{
		set_phoned(contact_id){
			return
			/*if(this.edition == contact_id) return
			let self = this
			let company_id = self.company.id

			let data = new FormData();
			data.append('contact_id', contact_id);
			data.append('company_id', company_id);

			// this.contacts[contact_id].phoned = true
			self.$store.dispatch('update_companies_calls', company_id)
			self.$store.dispatch('set_contact_id_phoned', {contact_id, company_id})

			axios.post('/plugin_projet/plugin_projet_iannuaire/update_contact_tel', data).then()
			*/
		},
		getNomPrenom(contact){
			let nom_prenom = [];
			if(contact.gender){
				if(contact.gender == 'F'){
					nom_prenom.push(this.$t('Mme'))
				}else if(contact.gender == 'M'){
					nom_prenom.push(this.$t('M.'))
				}
			}
			if(contact.first_name) nom_prenom.push(contact.first_name);
			if(contact.last_name) nom_prenom.push(contact.last_name);
			return nom_prenom.join(' ');
		},
		getUrlAbsolue(url){
			url = String(url)
			if(!url) return false
			return url.substring(0, 4) == 'http' ? url : 'http://'+url;
		},
		edit(contact){
			this.civilite_edition = contact.gender
			this.edition = contact.id
		},
		save(contact_id, key){
			let self = this
			this.erreurs[key] = undefined

			let mail     = String(this.$refs['contact_'+contact_id+'_mail'][0].$el.textContent).trim()
			let last_name = String(this.$refs['contact_'+contact_id+'_last_name'][0].$el.textContent).trim()
			
			let erreurs = false

			//AS--> Vérifie que le mail n'a pas été supprimé
			if(mail == '' && contact_id != 'new' && this.contacts[key]['mail'] != ''){
				if(!erreurs) erreurs = {}
				erreurs['mail'] = 'saisir_valeur'
			}

			//AS--> Vérifie que nom n'est pas vide
			if(last_name == ''){
				if(!erreurs) erreurs = {}
				erreurs['last_name'] = 'saisir_valeur'
			}

			//AS--> S'il y a une erreur on s'arrète là
			let _erreurs = _.cloneDeep(this.erreurs)
			if(erreurs){
				_erreurs[contact_id] = erreurs
				this.erreurs = _erreurs
				return
			}else{
				_erreurs[contact_id] = false
				this.erreurs = _erreurs
			}

			let contact = {..._.cloneDeep(this.contacts[key]), ...{
				gender: 	 this.civilite_edition,
				last_name:   last_name,
				first_name:  String(this.$refs['contact_'+contact_id+'_first_name'][0].$el.textContent).trim(),
				mail:        mail,
				phoneNumber: String(this.$refs['contact_'+contact_id+'_phoneNumber'][0].$el.textContent).trim(),
				job:    	 String(this.$refs['contact_'+contact_id+'_job'][0].$el.textContent).trim(),
				linkedin:    String(this.$refs['contact_'+contact_id+'_linkedin'][0].$el.textContent).trim(),
			}}

			let toSave = _.clone(contact)

			toSave.contact_id = undefined;
			toSave.editable   = undefined;
			toSave.updated_by = undefined;

			if(this.edition == 'new'){
				this.contacts.forEach(function(contact){
					if(contact.id == 'new') self.contacts = _.without(self.contacts, contact)
				})
			}

			//AS--> Envoi le contact à l'API
			axios({
				method: this.edition == 'new' ? 'post' : 'put',
				url: this.edition == 'new' ? 'api=inex/contacts/' : 'api=inex/contacts/'+this.edition, 
				data: toSave
			}).then(function(response){
				console.log(response);
				if(response.data && response.data.data && response.data.data.id){
					
					//AS--> Ajoute les données ajustées sur le contact
					contact.id       = response.data.data.id
					contact.editable = true;

					//AS--> Met à jour la liste de contacts globale
					let _contacts  = _.cloneDeep(self.contacts)
					_contacts[key] = _.clone(contact)
					self.contacts  = _contacts
				}
			})

			//AS--> Ferme l'édition du contact
			this.edition = false
		},
		suppr(contact_id){
			let self = this;

			this.$dialog.confirm(self.$t('contact_delete_confirm'), { 
					loader: true, 
					okText: self.$t('oui_le_supprimer'),
					cancelText: self.$t('non_annuler'),
					customClass: 'dialog-parme confirm-suppression-contact'
				})
				.then(dialog => {
					axios.delete('api=inex/contacts/'+contact_id+'/'+this.company.id)
					.then(function(response){
						console.log(response);
					})
					let contacts = []
					self.contacts.forEach(function(contact){
						if(contact.id != contact_id) contacts.push(contact)
					})
					self.contacts = contacts
					dialog.close();

				});
		},
		addContact(){
			let contact = null;

			//AS--> Récupère le contact New s'il existe déjà
			this.contacts.forEach(function(c){if(c.id == 'new') contact = c})

			console.log(contact);

			//AS--> Sinon on en crée un vierge
			if(!contact){
				contact = {
					id: 'new',
					id_national: this.company.id,
					gender: 'M',
					first_name: '',
					last_name: '',
					phoneNumber: '',
					mail: '',
					job: '',
					linkedin: ''
				}
				this.contacts.push(contact)
			}
			this.edit(contact)
		},
		cancel(contact_id, key){
			let self = this;

			let _erreurs = _.cloneDeep(this.erreurs)
			_erreurs[contact_id] = false
			this.erreurs = _erreurs

			this.contacts.forEach(function(contact, i){
				if(contact.id == 'new') self.contacts.splice(i,1);
			})
			this.edition = false
		},
		canEdit(contact){
			return contact && contact.editable == true
		}
	},
	components:{
		ContentEditable
	}
}