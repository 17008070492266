import _ from 'lodash'

export default {
	props: ['params'],
	computed: {
		valeur: {
			get () {
				this.$store.state.company.edition_matiere_uniq; //AS--> Pour la réactivité avec les valeurs modifiées à la volée qui ne changent pas dans edition_matiere mais dont le changement doit s'opérere dans le champ
				let field = this.params.field.split('.');
				let val = null;
				if(field.length == 2){
					// val = this.$store.state.company.edition_matiere[field[0]] ? this.$store.state.company.edition_matiere[field[0]][field[1]] : null;
					if(field[0] == 'carac' || field[0] == 'characteristics'){
						this.$store.state.company.edition_matiere.characteristics.forEach(function(carac){
							if(carac.forme_id == field[1]) val = carac.value
						})
					}else{
						val = this.$store.state.company.edition_matiere[field[0]] ? this.$store.state.company.edition_matiere[field[0]][field[1]] : null;
					}
				}else{
					// val = this.$store.state.company.edition_matiere[field[0]];
					val = this.$store.state.company.edition_matiere[field[0]]
				}
				if(typeof val == 'undefined') val = null;
				return val;
			},
			set (value) {

				//AS--> Vérifications
				if(!_.isNull(value) && this.params.type && this.params.type == 'number'){
					let regex = /[^0-9.,]/i;
					value = value.replace(regex, '');
				}

				let matiere = this.$store.state.company.edition_matiere;
				let field   = this.params.field.split('.');

				//AS--> Si le field est composé de 2 parties
				if(field.length == 2){

					//AS--> Si la première partie est l'entrée des caractéristiques
					if(field[0] == 'carac' || field[0] == 'characteristics'){
						let founded = false
						matiere.characteristics.forEach(function(carac){
							//AS--> On trouve la caractéristique relative et on la modifie
							if(carac.forme_id == field[1]){
								carac.value = value
								founded     = true
							}
						})
						//AS--> Si on n'a pas trouvé la caractéristique, on l'ajoute
						if(!founded){
							matiere.characteristics.push({forme_id: field[1], value})
						}

					//AS--> Si ce n'est pas pour les caractéristiques
					}else{
						if(!matiere[field[0]]) matiere[field[0]] = {};
						matiere[field[0]][field[1]] = value;
					}


				}else{
					matiere[field[0]] = value;
				}

				this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', matiere);
			}
		}
	}
}