import Champ from "./champ";

export default {
	mixins: [Champ],
	created(){},
	methods: {},
	computed:{
		size(){
			return this.params.size ? this.params.size : 3;
		},
		type(){
			return this.params.type ? this.params.type : 'number';
		}
	},
}