import _ from 'lodash'
import Utils from '../../common/utils.js';

export default {
	mixins: [Utils],
	data() {
		return {
			
		}
	},
	props: ['element'],
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		
	}
}