
export default {
	data() {
		return {
			company: this.$store.state.company.company,
			familles_secondaires_ids: [], 	//AS--> Si des familles ne faisant pas parti du sourcing sont envoyées, elles ne seront pas modifiée en FO par l'internaute mais seront toujours envoyées à l'API pour enregistrement grace à la fonction switchValue qui ne gère que les ids éditables
			edition: false,
			voir_plus: false,
			limitation: false,
			nbr_familles_secondaires_max: 2
		}
	},
	created(){
		//AS--> Force le mode édition à la création si on est en mode 'monentreprise'
		if(this.canEdit && this.$store.state.ui.content == 'monentreprise') this.editionOn();
	},
	watch:{
		"$store.state.company.company"(newValue, oldValue){
			this.company = this.$store.state.company.company
		}
	},
	methods:{
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.familles_secondaires_ids = _.clone(this.company.complement.familles_secondaires_ids);
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},

		switchValue(familleId){
			let self = this
			let nbr_familles_actives = _.intersection(this.familles_secondaires_ids, this.getFamillesIds).length;

			//AS--> Retire la famille
			if(this.familles_secondaires_ids.includes(familleId)){
				this.familles_secondaires_ids = _.without(this.familles_secondaires_ids, familleId);

			//AS--> Ajoute la famille
			}else{
				if(nbr_familles_actives >= this.nbr_familles_secondaires_max){
					this.limitation = true
					setTimeout(function(){
						self.limitation = false
					},2500)
				}else{
					this.familles_secondaires_ids.push(familleId);
				}
			}

		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(){
			let self = this;

			this.$store.dispatch('entreprise_modifie_champ', {
				id: this.company.id,
				champ: 'familles_secondaires_ids',
				valeur: this.familles_secondaires_ids
			})

			this.edition = false;
		}
	},
	computed:{
		canEdit(){
			return this.$store.getters.userCanEditCompany('familles_principale_secondaires', this.company.id)
		},
		getFamillePrincipaleIds(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_principales_ids.includes(famille.id)) familles.push(famille.id)
			})
			return familles;
		},
		getFamillePrincipale(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_principales_ids.includes(famille.id)) familles.push(famille.titre)
			})
			return familles.join(' / ');
		},
		getFamillesSecondaires(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_secondaires_ids.includes(famille.id)) familles.push(famille.titre)
			})
			return familles.join(' / ');
		},
		//AS--> Récupère les familles affectées au sourcing
		getFamilles(){
			return _.sortBy(_.cloneDeep(this.$store.state.ui.sourcing.familles), ['titre']);
		},
		//AS--> Récupère les ids des familles affectées au sourcing
		getFamillesIds(){
			return _.reduce(this.$store.state.ui.sourcing.familles, function(ids, famille){if(famille.parent_id) ids.push(famille.id); return ids}, []);
		}
	}
}