import axios from '../../common/axios.js'
import ContentEditable from '../../common/forms/ContentEditable.vue'

export default {
	data() {
    return {
      company: this.$store.state.company.company,
      edition: false,
      civilite_edition: null,
      erreurs: {}
    }
  },
  watch:{
    "$store.state.company.company":function(after, before){
      this.company = after
      this.erreurs = {}
    }
  },
  methods:{
    set_phoned(contact_id){
      if(this.edition == contact_id) return
      let self = this
      let company_id = self.company.id

      let data = new FormData();
      data.append('contact_id', contact_id);
      data.append('company_id', company_id);

      // this.contacts[contact_id].phoned = true
      self.$store.dispatch('update_companies_calls', company_id)
      self.$store.dispatch('set_contact_id_phoned', {contact_id, company_id})

      axios.post('/plugin_projet/plugin_projet_iannuaire/update_contact_tel', data).then()
    },
    getNomPrenom(contact){
      let nom_prenom = [];
      if(contact.civilite_id){
        if(contact.civilite_id == 1){
          nom_prenom.push(this.$t('Mme'))
        }else if(contact.civilite_id == 3){
          nom_prenom.push(this.$t('M.'))
        }
      }
      if(contact.prenom) nom_prenom.push(contact.prenom);
      if(contact.nom) nom_prenom.push(contact.nom);
      return nom_prenom.join(' ');
    },
    getUrlAbsolue(url){
      url = String(url)
      if(!url) return false
      return url.substring(0, 4) == 'http' ? url : 'http://'+url;
    },
    edit(contact){
      this.civilite_edition = contact.civilite_id
      this.edition = contact.id
    },
    save(contact_id, key){
      this.erreurs[key] = undefined

      let email = String(this.$refs['contact_'+contact_id+'_email'][0].$el.textContent).trim()
      let nom   = String(this.$refs['contact_'+contact_id+'_nom'][0].$el.textContent).trim()
      
      let erreurs = false

      //AS--> Vérifie que l'email n'a pas été supprimé
      if(email == '' && contact_id != 'new' && this.company.complement.contacts[key]['email'] != ''){
        if(!erreurs) erreurs = {}
        erreurs['email'] = 'saisir_valeur'
      }

      //AS--> Vérifie que nom n'est pas vide
      if(nom == ''){
        if(!erreurs) erreurs = {}
        erreurs['nom'] = 'saisir_valeur'
      }

      //AS--> S'il y a une erreur on s'arrète là
      let _erreurs = _.cloneDeep(this.erreurs)
      if(erreurs){
        _erreurs[contact_id] = erreurs
        this.erreurs = _erreurs
        return
      }else{
        _erreurs[contact_id] = false
        this.erreurs = _erreurs
      }

      let contact = {..._.cloneDeep(this.company.complement.contacts[key]), ...{
        civilite_id: this.civilite_edition,
        nom:         nom,
        prenom:      String(this.$refs['contact_'+contact_id+'_prenom'][0].$el.textContent).trim(),
        email:       email,
        tel:         String(this.$refs['contact_'+contact_id+'_tel'][0].$el.textContent).trim(),
        fonction:    String(this.$refs['contact_'+contact_id+'_fonction'][0].$el.textContent).trim(),
        linkedin:    String(this.$refs['contact_'+contact_id+'_linkedin'][0].$el.textContent).trim(),
      }}

      this.$store.dispatch('entreprise_modifie_contact', {key, contact})

      //AS--> Ferme l'édition du contact
      this.edition = false
    },
    suppr(contact_id){
      let self = this;

      this.$dialog.confirm(self.$t('contact_delete_confirm'), { 
          loader: true, 
          okText: self.$t('oui_le_supprimer'),
          cancelText: self.$t('non_annuler'),
          customClass: 'dialog-parme confirm-suppression-contact'
        })
        .then(dialog => {
          self.$store.dispatch('entreprise_supprime_contact', contact_id)
          dialog.close();

        });
    },
    addContact(){
      let contact = null;

      //AS--> Récupère le contact New s'il existe déjà
      this.company.complement.contacts.forEach(function(c){if(c.id == 'new') contact = c})

      console.log(contact);

      //AS--> Sinon on en crée un vierge
      if(!contact){
        contact = {
          id: 'new',
          entreprise_id: this.company.id,
          civilite_id: 3,
          nom: '',
          prenom: '',
          email: '',
          tel: '',
          fonction: '',
          linkedin: '',
          ordre: 99
        }
        this.company.complement.contacts.push(contact)
      }
      this.edit(contact)
    },
    cancel(contact_id, key){
      let self = this;

      let _erreurs = _.cloneDeep(this.erreurs)
      _erreurs[contact_id] = false
      this.erreurs = _erreurs

      this.company = this.$store.state.company.company
      this.company.complement.contacts.forEach(function(contact, i){
        if(contact.id == 'new') self.company.complement.contacts.splice(i,1);
      })
      this.edition = false
    }
  },
  computed:{
    canEdit(){
      return this.$store.getters.userCanEditCompany('contacts_lies', this.company.id)
    }
  },
  components:{
    ContentEditable
  }
}