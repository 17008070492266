import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { 
	HardBreak,
	Heading,
	BulletList,
	ListItem,
	Bold,
	Italic,
	History,
} from 'tiptap-extensions'

export default {
	data() {
		return {
      		company: this.$store.state.company.company,
			edition: false,
			editor_commentaire_sourcing: new Editor({
				content: this.$store.state.company.company.complement.commentaire_sourcing || '',
				extensions: [
					new BulletList(),
					new HardBreak(),
					new Heading({levels: [4,5]}),
					new ListItem(),
					new Bold(),
					new Italic(),
					new History(),
				]
			})
		}
	},
	created(){
		if(this.canEdit('commentaire_sourcing') && this.$store.state.ui.content == 'monentreprise') this.editionOn();
	},
	watch:{
		"$store.state.company.company"(newValue, oldValue){
			this.company = this.$store.state.company.company
		}
	},
	methods:{
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.editor_commentaire_sourcing.setContent(this.company.complement.commentaire_sourcing || '')
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(champ){
			let self = this;

			this.$store.dispatch('entreprise_modifie_champ', {
				id: this.company.id,
				champ: champ,
				valeur: this['editor_'+champ].getHTML()
			})

			this.edition = false;
		},
		canEdit(champ){
			let self = this;
			let canEdit = true;

			return canEdit;
		}
	},
	computed:{},
	beforeDestroy() {
		// Always destroy your editor instance when it's no longer needed
		this.editor_commentaire_sourcing.destroy()
	},
	components: {
		EditorContent,
		EditorMenuBar
	}
}