import Champ from "./champ";
import Utils from "../../../common/utils.js";
import ImageAuto from "../../../common/ui/image_auto.vue";

export default {
	mixins: [Champ, Utils],
	data(){
		return {
			image: null,
			imagePreview: null,
			showPreview: false,
			reader: new FileReader()
		}
	},
	created(){
		if(this.valeur && typeof this.valeur == 'object' && this.valeur.type && this.valeur.type.substring(0,6) == 'image/'){
			this.image = this.valeur;
			this.prepareImage()
		}
	},
	methods:{
		submitForm(){
			//AS--> On met à jour l'image stockée dans le store si le check est bon
			if(this.checkImage(this.image)) this.$store.commit('UI_UPLOAD_IMAGE', this.image);

			//AS--> et on ferme la modale
			this.$store.commit('UI_SET_MODAL', {modal: null});

		},
		onFileChange(event){
			this.image = event.target.files[0];
			this.prepareImage()

		},
		prepareImage(file){
			/*
			Add an event listener to the reader that when the file
			has been loaded, we flag the show preview as true and set the
			image to be what was read from the reader.
			*/
			this.reader.addEventListener("load", function () {
				this.showPreview = true;
				this.imagePreview = this.reader.result;
				this.valeur = this.image;
			}.bind(this), false);

			
			if(this.checkImage(this.image)){
				this.reader.readAsDataURL( this.image );
			}
		},
		checkImage(image){
			return image && /\.(jpe?g|png|gif)$/i.test( image.name );
		},
	},
	computed:{
		isErreur(){
			return this.image && !this.checkImage(this.image);
		},
		getSrc(){
			return this.imagePreview ? this.imagePreview : this.valeur
		}
	},
	components:{
		ImageAuto
	}
}

