import _ from 'lodash'
import Utils from '../../common/utils.js';
import axios from '../../common/axios.js'

export default {
	mixins: [Utils],
	data() {
		return {
			edition: false,
			voir_plus: false,
			fields: [],
			values_temp: [],
		}
	},
	props: ['element'],
	created(){
		this.fields = _.cloneDeep(this.element.fields)
	},
	mounted(){
		this.fields = _.cloneDeep(this.element.fields)
	},
	methods: {
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.edition = true;
			this.values_temp = _.cloneDeep(this.fields)
		},
		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},
		//AS--> Sauvegarde et ferme l'éditeur
		editionSave(){
			let self = this;
			this.fields = _.cloneDeep(this.values_temp)
			this.edition = false
			this.fields.forEach(function(field){
				if(field.editable) self.postInexFieldData(field.field, field.value)
			})
		},
		switchValue(key, option_value){
			if(this.values_temp[key].value.includes(option_value)){
				this.values_temp[key].value = _.without(this.values_temp[key].value, option_value);
			}else{
				//AS--> Fait de la place si besoin
				if(this.values_temp[key].limit && this.values_temp[key].value.length >= this.values_temp[key].limit){
					if(this.values_temp[key].limit == 1){
						this.values_temp[key].value = []
					}else{
						this.values_temp[key].value = this.values_temp[key].value.slice(-this.values_temp[key].limit+1)
					}
				}

				this.values_temp[key].value.push(option_value);
			}
		},
		getAffiches(key){
			let self = this;
			let options = [];
			this.getList(key).forEach(function(option){
				if(self.fields[key].value.includes(option.value)) options.push(self.i18n(option.name));
			})
			return options.join(', ');
		},
		getList(key){
			return typeof this.fields[key].list == 'undefined' ? [] : this.fields[key].list
		}
	},
	computed:{
		nbrToShow(){
			return this.element.max_show ? this.element.max_show : this.max_show
		},
		canEdit(){
			return this.element.editable
		}
	},
	components:{
		
	}
}