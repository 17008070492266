import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: ['element'],
	created(){
		
	},
	methods: {
		proprietaireContact(){
			this.$store.commit('UI_SET_MODAL', {modal: 'parcelle_proprietaire_contact'});
		}
	},
	computed:{
		
	},
	components:{
		
	}
}