import _ from 'lodash'
import Utils from '../../common/utils.js';
import axios from '../../common/axios.js'
import CustomSelect from '../forms/Select.vue'

export default {
	mixins: [Utils],
	data() {
		return {
			edition: true,
			fields_parents: [],
			value: [],
			child_edition_field: null,
			child_edition_value: null,
			childs_edited: []
		}
	},
	props: ['element'],
	created(){
		this.fields_parents = _.cloneDeep(this.element.fields)
	},
	mounted(){
		this.fields_parents = _.cloneDeep(this.element.fields)
	},
	methods: {
		getParentsForBloc(bloc_i){
			let self = this
			let bloc = this.fields_parents[bloc_i]
			let parents = []
			if(typeof bloc.value == 'object' && bloc.value.length){
				bloc.value.forEach((value)=>{
					self.element.parents.forEach((parent)=>{
						if(parent.code == value){
							parents.push(parent)
						}
					}
				)})
			}
			return parents
		},
		getParent(parent_code){
			let self = this
			let parent = {}

			if(parent_code){
				//AS--> Si le parent est de type string, on cherche sa définition
				this.element.parents.forEach((_parent)=>{
					if(_parent.code == parent_code){
						parent = _parent
					}
				})
			}

			return parent
		},
		getChilds(parent){
			let self = this
			let childs = []

			if(parent){
				//AS--> Si le parent est de type string, on cherche sa définition
				if(typeof parent == 'string') parent = this.getParent(parent)

				parent.childs.forEach((parent_child)=>{
					self.element.childs.forEach((child)=>{
						if(child.field == parent_child){
							childs.push(child)
						}
					}
				)})
			}

			return childs
		},

		//AS--> Renvoie une liste de zones de modification possibles. Si limite = 8, renvoie 8 zones, en indiquant la valeur déjà appliquée à chacun des slots, ou null si pas de valeur
		getParentEditableForBloc(bloc_i){
			let bloc = this.fields_parents[bloc_i]
			let limit = bloc.limit ? bloc.limit : 10
			let parents = []
			let _parents = bloc.value

			for(let i = 0; i < limit; i++){
				if(_parents[i]){
					parents.push(_parents[i])
				}else{
					parents.push(null)
				}
			}

			return parents
		},

		//AS--> Renvoie une liste de parents sélectionnables dans un select de choix, en ignorant tous ceux déjà sélectionnées, mais en y ajoutant 'parent' qui est le choix en cours pour ce select
		getListParents_with(parent){
			let list = []

			//AS--> Récupère la liste de tous les parents utilisés
			let used_parents = []
			this.fields_parents.forEach((bloc)=>{
				if(typeof bloc.value == 'object' && bloc.value.length){
					//AS--> Concatène les parents
					used_parents = used_parents.concat(bloc.value);
				}
			})

			//AS--> Retire de la liste de parents utilisés le code du parent en paramètre de la fonction
			if(parent){
				used_parents = used_parents.filter((code)=>{
					return code != parent
				})
			}

			//AS--> Ajoute à la liste tous les parents qui ne sont pas encore utilisés
			this.element.parents.forEach((_parent)=>{
				if(used_parents.indexOf(_parent.code) == -1){
					list.push({
						label: this.i18n(_parent.name),
						value: _parent.code
					})
				}
			})

			return list
		},

		getChildValue(child_field){
			let child = _.find(this.element.childs, {field: child_field})
			if(child) return child.value
			else return null
		},

		getChildValueShow(child_field){
			let child = null
			this.childs_edited.forEach((_child, index) => {
				if(_child.field == child_field) child = _child.value
			})
			if(child === null) child = this.getChildValue(child_field)
			if(!child && typeof child != 'numeric') child = null
			return child
		},

		childEditionOn(child_field){
			let self = this

			//AS--> S'il y avait déjà une édition en cours, on la stoppe proprement
			if(this.child_edition_field) this.childEditionOff()

			//AS--> On défini l'édition du nouvel enfant
			this.child_edition_field = child_field

			//AS--> On récupère la valeur de l'enfant
			this.child_edition_value = this.getChildValueShow(child_field)

			//AS--> On focus l'input
			setTimeout(function(){
				self.$refs.inputChild[0].focus()
				self.resizeInput()
			},50)
		},
		childEditionOff(){

			//AS--> S'il y a eu un changement de valeur, on stocke la valeur modifiée dans un array temporaire
			if(this.child_edition_value != this.getChildValue(this.child_edition_field)){
				let found = false
				this.childs_edited.forEach((child, index) => {
					if(child.field == this.child_edition_field){
						this.childs_edited[index].value = this.child_edition_value
						found = true
					}
				})
				if(!found){
					this.childs_edited.push({
						field: this.child_edition_field,
						value: this.child_edition_value
					})
				}
			}else{
				this.childs_edited = this.childs_edited.filter((child) => child.field != this.child_edition_field)
			}

			this.child_edition_field = null
			this.child_edition_value = null
		},
		resizeInput() {
			this.$refs.inputChild[0].style.width = (this.$refs.inputChild[0].value.length*6.5)+20 + "px";
		},

		//AS--> Annulation
		editionOff(){
			this.childs_edited = []
			this.fields_parents = _.cloneDeep(this.element.fields)
		},

		//AS--> Sauvegarde
		editionSave(){

			let self = this

			let toSave   = {}
			let needSave = false

			//AS--> Sauvegarde des parents au niveau de l'élément et post une modification sur l'API iNex
			this.element.fields.forEach((field, index) => {
				if(!_.isEqual(field.value, self.fields_parents[index].value)){
					this.element.fields[index].value = self.fields_parents[index].value
					toSave[field.field] = _.without(self.fields_parents[index].value, null)
					needSave = true
				}
			})

			//AS--> Sauvegarde des childs au niveau de l'élément les nouvelles valeurs et post une modification sur l'API iNex
			this.childs_edited.forEach((child, index) => {
				this.element.childs.forEach((_child, index) => {
					if(_child.field == child.field){
						this.element.childs[index].value = child.value
						toSave[child.field] = child.value
						needSave = true
					}
				})
			})

			if(needSave) self.postInexFieldsData(toSave)

			//AS--> Reset les variables de modification
			this.editionOff()

		}
	},
	computed:{
		getBlocs(){
			if(typeof this.element.fields == 'object'){
				return this.element.fields
			}
			return []
		},
		canEdit(){
			return this.element.editable
		},
		hasChanged(){
			let self = this
			let hasChanged = false
			if(this.childs_edited.length) hasChanged = true
			this.element.fields.forEach((field, index) => {
				if(!_.isEqual(field.value, self.fields_parents[index].value)) hasChanged = true
			})
			return hasChanged
		}
	},
	components:{
		CustomSelect
	}
}