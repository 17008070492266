<template>
  <span ref="champ" :contenteditable="edition" :class="{field_edition: edition}" :placeholder="placeholder">{{data}}</span>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
export default {
  name: "ContentEditable",
  data() {
    return {};
  },
  props: {
    data: [String, Number],
    edition: false,
    placeholder: String,
    focusOnEdition: false
  },
  watch:{
    edition: function(after, before){
      let self = this
      if(after == false) this.$refs.champ.textContent = this.data
      if(after == true && this.focusOnEdition){
        setTimeout(function(){
          self.$refs.champ.focus()
        }, 50)
      }
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
  },
  computed: {
  },
  components: {},
};
</script>
<style scoped lang="scss">
span[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: gray;
  background-color: transparent;
  pointer-events: none;
}
</style>
