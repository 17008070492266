import _ from 'lodash'

//AS--> Eléments génériques
import ElementZone from './zone.vue'

export default {
	data() {
		return {
			onglet_actif: 0
		}
	},
	props: ['elements'],
	created(){
		
	},
	methods: {
		getElements(elements){
			let self = this

			if(elements.length){
				elements.forEach(function(element){

					//AS--> Si c'est un type wrapper
					if(element.content){
						if(!element.format) element.format = 'zone'
						element.content = self.getElements(element.content)

					//AS--> Sinon
					}else{
						if(!element.format) element.format = 'text'

					}
				})
			}

			return elements
		},
		
	},
	computed:{
		onglets(){
			if(this.elements && this.elements.complements){
				return this.elements.complements
			}else if(this.elements && this.elements.properties && this.elements.properties.complements){
				return this.elements.properties.complements
			}
			return [];
		},
	},
	components:{
		ElementZone
	}
}