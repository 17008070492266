import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: ['element','top'],
	created(){
		
	},
	mounted(){
		console.log(this.calques);
	},
	methods: {
		style(calque){
			let styles = {};
			if(calque.properties && calque.properties.style){
				if(calque.properties.style.fillColor != undefined) 		styles['background-color'] 	= calque.properties.style.fillColor;
				if(calque.properties.style.fillOpacity != undefined) 	styles['opacity'] 			= calque.properties.style.fillOpacity;
				if(calque.properties.style.strokeColor != undefined) 	styles['border-color'] 		= calque.properties.style.strokeColor;
				if(calque.properties.style.strokeWeight != undefined){
					styles['border-width'] 		= calque.properties.style.strokeWeight+'px';
					let size = 11 - parseInt(styles['border-width'])*2
					styles['width'] 		= size+'px';
					styles['height'] 		= size+'px';
				}
			}
			return styles;
		}
	},
	computed:{
		calques(){
			if(this.top && this.top.properties && this.top.properties.calques) return this.top.properties.calques
			return []
		}
	},
	components:{
		
	}
}