import Champ from "./champ";

export default {
	mixins: [Champ],
	created(){},
	methods: {},
	computed:{
		rows(){
			return this.params.rows ? this.params.rows : 8;
		},
		cols(){
			return this.params.cols ? this.params.cols : 80;
		}
	}
}