import Champ from "./champ";

export default {
	mixins: [Champ],
	created(){
		if(this.debugs.edition_matiere){
			console.log('=> '+this.i18n(this.params.label)+ ' ('+this.params.field+')', 'ChampText')
		}
	},
	methods: {},
	computed:{
		size(){
			return this.params.size ? this.params.size : 50;
		},
		type(){
			return this.params.type ? this.params.type : 'text';
		}
	}
}