import _ from 'lodash'
import Utils from '../../common/utils.js';
import axios from '../../common/axios.js'

export default {
	mixins: [Utils],
	data() {
		return {
			edition: false,
			max_show: 3,
			voir_plus: false,
			value: [],
			values_temp: []
		}
	},
	props: ['element'],
	created(){
		if(this.element.value) this.value = _.cloneDeep(this.element.value)
	},
	mounted(){
		if(this.element.value) this.value = _.cloneDeep(this.element.value)
	},
	methods: {
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.edition = true;
			this.values_temp = this.value
		},
		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},
		//AS--> Sauvegarde et ferme l'éditeur
		editionSave(){
			this.value = _.cloneDeep(this.values_temp)
			this.edition = false
			this.postInexFieldData(this.element.field, this.value)
		},
		switchValue(option_value){
			if(this.values_temp.includes(option_value)){
				this.values_temp = _.without(this.values_temp, option_value);
			}else{
				this.values_temp.push(option_value);
			}
		}
	},
	computed:{
		nbrToShow(){
			return this.element.max_show ? this.element.max_show : this.max_show
		},
		canEdit(){
			return this.element.editable
		},
		getAffiches(){
			let self = this;
			let options = [];
			this.getList.forEach(function(option){
				if(self.value.includes(option.value) && (options.length < self.nbrToShow || self.value.length == (self.nbrToShow+1))) options.push(option);
			})
			return options;
		},
		getMasques(){
			let self = this;
			if(this.value.length <= (self.nbrToShow+1)) return null;
			let options = [];
			let i = 0;
			this.getList.forEach(function(option){
				if(self.value.includes(option.value)) i++;
				if(i > self.nbrToShow && self.value.includes(option.value)) options.push(option);
			})
			return options;
		},
		getList(){
			return typeof this.element.list == 'undefined' ? [] : this.element.list
		}
	},
	components:{
		
	}
}