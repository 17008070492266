import champ_text from './champs/champ_text.vue'
import champ_textarea from './champs/champ_textarea.vue'
import champ_tinytext from './champs/champ_tinytext.vue'
import champ_select from './champs/champ_select.vue'
import champ_wysiwyg from './champs/champ_wysiwyg.vue'
import champ_imageupload from './champs/champ_imageupload.vue'
import ImageAuto from '../../common/ui/image_auto.vue'


export default {
	data() {
		return {
			menu: null,
			loaded: true,
			checkErreursInterval: null,
			checkErreurs: null,
			sous_titre: '--',
			validated: false,
			saved: false,
			mode_sauvegarde: null
		}
	},
	props:{
		affiche_identite: false
	},
	watch:{
		"$store.state.company.company.complement": function(newValue, oldValue){
			this.loaded = this.$store.state.company.company.complement && this.$store.state.company.company.complement.id && this.$store.state.company.company.complement.id == this.$store.state.company.company.id;
		},
		"$store.state.company.company": function(newValue, oldValue){
			let self = this
			if(newValue.complement && newValue.complement.identifiant && newValue.complement.identifiant == newValue.id){
				this.loaded = true;
			}else{
				this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', null);
				this.loaded = false;
				this.$store.dispatch('loadiNexCompany').finally(function(){
					self.loaded = true;
				})
			}
		},
		"$store.state.company.edition_matiere": function(newValue, oldValue){
			let self = this;
			//AS--> Au premier chargement et après changement de matière, on revient sur le premier onglet
			this.menu = this.$store.state.ui.edition_matiere_fo[0];


			if(this.debugs.edition_matiere){
				console.log('=============================================');
				console.log('Edition matière :', _.cloneDeep(this.$store.state.company.edition_matiere))
				this.$store.state.ui.edition_matiere_fo.forEach(function(menu){
					console.log('*** Menu =>', self.i18n(menu.title), self.hasRequired(menu) ? 'requiered' : 'not required', '"'+self.getValueShow(menu)+'"')
				})
			}
		},
		"$store.state.company.edition_save_matiere": function(newValue, oldValue){
			let self = this;
			//AS--> Après la sauvegarde d'une matière, affiche le message de matière sauvegardée
			if(newValue === false && oldValue === true){
				this.saved = true;
			}
		}
	},
	created(){
		let self = this;

		if(this.debugs.edition_matiere){
			console.log('YAML édition matière :', this.$store.state.ui.edition_matiere_fo)
		}

		this.loaded = false;
		this.$store.dispatch('loadiNexCompany').finally(function(){
			self.loaded = true;
		})

		if(this.$store.state.company.company.complement && this.$store.state.company.company.complement.id && this.$store.state.company.company.complement.id == this.$store.state.company.company.id){
			this.loaded = true;
		}else{
			this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', null);
		}

		this.checkErreursInterval = setInterval(function(){
			self.checkErreurs = self.$el.querySelectorAll('.menu .error').length;
		},500)
	},
	methods: {
		setMatiere(matiere){
			this.saved     = false;
			this.validated = false;
			//AS--> Si c'est la création d'une matière, on ajoute les éléments minimum
			if(!matiere || !matiere.product_id){
				if(!this.$store.state.ui.sourcing.annee_timestamp){
					let d     = new Date();
					var annee = d.getFullYear();
					matiere.product_id = annee-1
				}else{
					matiere.product_id = Date.now()
				}
				matiere.characteristics = []
				matiere.new = true
			}else{
				if(typeof matiere.new != 'undefined') delete matiere.new
			}
			this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', _.cloneDeep(matiere));
		},
		deleteMatiere(matiere){
			let self = this
			this.$dialog.confirm(self.$t('edition_matiere_delete_confirm'), { loader: true })
				.then(dialog => {
					dialog.close();
					self.$store.dispatch('entreprise_supprime_matiere', {matiere})
				});
		},
		setMenu(menu){
			this.menu = menu;
		},
		hasRequired(menu){
			let required = false;
			menu.fields.forEach(function(field){
				if(field.required) required = true;
			});
			return required;
		},
		fieldHasValue(field, value){
			let hasValue = false;
			if(value || value === 0) hasValue = true;
			if(field.forbidden || field.forbidden == 0){
				if(typeof field.forbidden == 'object' && field.forbidden.length && field.forbidden.includes(value)) hasValue = false;
				else if(field.forbidden == value) hasValue = false;
			}
			return hasValue;
		},
		getStatus(menu){
			let self = this;
			self.$store.state.company.edition_matiere_uniq;
			let hasRequired                 = this.hasRequired(menu);
			let nbr_fields                  = menu.fields.length;
			let nbr_fields_hasData          = 0;
			let nbr_fields_required         = 0;
			let nbr_fields_required_hasData = 0;
			menu.fields.forEach(function(field){
				let value = self.getValue(field);
				let hasData = self.fieldHasValue(field, value);
				if(hasData) nbr_fields_hasData++;
				if(value || value === 0) nbr_fields_hasData++;
				if(field.required && !field.nullable){
					nbr_fields_required++;
					if(hasData) nbr_fields_required_hasData++;
				}
			});
			// console.log(menu.title.fre, nbr_fields, nbr_fields_hasData, nbr_fields_required, nbr_fields_required_hasData);

			//AS--> S'il y a des champs requis et qu'ils ne sont pas tous renseignés, on retourne un status en erreur
			if(nbr_fields_required && nbr_fields_required_hasData < nbr_fields_required) return 'error';

			//AS--> Sinon, si tous les champs sont remplis, on retourne un status en success
			// if(nbr_fields_hasData == nbr_fields) return 'success';
			
			//AS--> Sinon, si au moins un champ est rempli, on retourne un succès
			if(nbr_fields_hasData >= 1) return 'success';


		},
		getValue(field){
			if(!this.$store.state.company.edition_matiere) return null;
			let _field = field.field.split('.');
			let val    = null;
			if(_field.length == 3){
				val = this.$store.state.company.edition_matiere[_field[0]] && this.$store.state.company.edition_matiere[_field[0]][_field[1]] ? this.$store.state.company.edition_matiere[_field[0]][_field[1]][_field[2]] : null;
			}else if(_field.length == 2){
				// val = this.$store.state.company.edition_matiere[field[0]] ? this.$store.state.company.edition_matiere[field[0]][field[1]] : null;
				if(_field[0] == 'carac' || _field[0] == 'characteristics'){
					this.$store.state.company.edition_matiere.characteristics.forEach(function(carac){
						if(carac.forme_id == _field[1]) val = carac.value
					})
				}else{
					val = this.$store.state.company.edition_matiere[_field[0]] ? this.$store.state.company.edition_matiere[_field[0]][_field[1]] : null;
				}
			}else{
				val = this.$store.state.company.edition_matiere[_field[0]];
			}
			return val;
		},
		getValueShow(menu){
			let self = this;
			let value = null;

			if(menu.fields.length && menu.fields.length == 1){
				let field = menu.fields[0];
				switch(field.format){
					case 'text':
					case 'tinytext':
					case 'textarea':
					case 'wysiwyg':
						value = this.getValue(field);
						break;
					case 'select':
						let val = this.getValue(field);
						
						let options = [];
						if(field.options){
							if(field.options.var){
								//AS--> Cas d'un select à monter sur la base d'un objet existant sur store.ui
								if(this.$store.state.ui[field.options.var]){
									_.forEach(this.$store.state.ui[field.options.var], function(option){
										options.push({
											val: option[field.options.value],
											name: option[field.options.name]
										});
									})
								
								//AS--> Cas d'un select à monter sur la base d'une nomenclature
								}else if(this.$store.getters.nomenclatureExist(field.options.var)){
									options = this.$store.getters.getNomenclatureList(field.options.var, this.$store.state.ui.langue);
								}

							//AS--> Cas d'un select à monter sur la base d'un objet fourni dans le yaml
							}else if(typeof field.options == 'object'){
								_.forEach(field.options, function(option, name){
									options.push({
										val: name,
										name: typeof option == 'object' ? self.i18n(option) : option
									});
								})

							}
						}else{
							options = this.$store.getters.getNomenclatureList_ByType('waste_editor', field.field);
						}

						if(options && options.length){
							options.forEach(function(option){
								if(option.val == val) value = option.name;
							})
						}
						break;
				}

				if(value && value.length > 70) value = value.substring(0,66)+' ...';
				if(value && field.unit) value = value + ' ' + field.unit
			}


			return value;
		},
		sauvegarder(brouillon){
			if(brouillon){
				this.mode_sauvegarde = 'brouillon'
				this.$store.dispatch('entreprise_modifie_matiere', {brouillon:true});
				this.validated = false;
			}else{
				if(this.checkErreurs === 0){
					this.mode_sauvegarde = 'concret'
					this.$store.dispatch('entreprise_modifie_matiere');
				}else{
					this.validated = true;
				}
			}
		},
		retour_matieres(){
			this.saved = false;
			this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', null);
		},
		getMatierePicto(matiere){
			let picto = null
			if(matiere.picto_personnalise){
				picto = matiere.picto_personnalise
			}else if(!matiere.picto_personnalise && matiere.picto){ 
				picto = matiere.picto
			}

			console.log(matiere);

			if(!picto) picto = 'api=inex/companies/'+this.$store.state.company.company.complement.identifiant+'/products/'+matiere.product_id+'/'+matiere.material_id+'/image'

			console.log(picto);

			return picto
		}
	},
	computed:{
		matieres(){
			let self = this
			let matieres = [];
			if(this.$store.state.company.dechets_api.length){
				matieres = this.$store.state.company.dechets_api;
			}

			matieres = _.sortBy(matieres, [function(m) {
				let ordre = m.draft ? '0' : '1';
				let name = m.name_specific ? m.name_specific : self.$store.getters.getDechetField(m.material_id, 'nom')
				if(typeof name == 'string') name = name.toLowerCase()
				return ordre + name
			}]);

			return matieres;
		}
	},
	beforeDestroy(){
		clearInterval(this.checkErreursInterval);
	},
	components: {
		champ_textarea,
		champ_text,
		champ_tinytext,
		champ_select,
		champ_wysiwyg,
		champ_imageupload,
		ImageAuto
	}
}