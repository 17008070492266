import _ from 'lodash'
import Debounce from 'lodash/debounce'
import axios from '../../common/axios.js'

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { 
	HardBreak,
	Heading,
	BulletList,
	ListItem,
	Bold,
	Italic,
	History,
} from 'tiptap-extensions'

export default {
	data() {
		return {
			sauvegarde: false,
			indexTimeout: null,

			commentaire: '',
			edition: true,
			editor_commentaire: new Editor({
				content: this.element.value,
				extensions: [
					new BulletList(),
					new HardBreak(),
					new Heading({levels: [4,5]}),
					new ListItem(),
					new Bold(),
					new Italic(),
					new History(),
				]
			}),

			commentaire_prive: '',
			edition_prive: true,
			editor_commentaire_prive: new Editor({
				content: this.element.value_prive,
				extensions: [
					new BulletList(),
					new HardBreak(),
					new Heading({levels: [4,5]}),
					new ListItem(),
					new Bold(),
					new Italic(),
					new History(),
				]
			}),
		}
	},
	props: ['element'],
	created(){
		this.commentaire = this.element.value
		this.commentaire_prive = this.element.value_prive
	},
	watch:{
		// commentaire(after, before){
		// 	if(after != before && before != ''){
		// 		this.save_modif()
		// 	}
		// }
		"$store.state.ui.class_impression": function(newValue, oldValue){
			if(newValue == 'print-parcelle') this.edition = this.edition_prive = false
		}
	},
	methods: {



		//AS--> Ouvre l'éditeur
		editionOn(){
			this.editor_commentaire.setContent(this.commentaire)
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(){
			this.commentaire = this.editor_commentaire.getHTML()
			this.edition     = false;
			this.saveCommentaire()
		},



		//AS--> Ouvre l'éditeur
		editionPriveOn(){
			this.editor_commentaire_prive.setContent(this.commentaire_prive)
			this.edition_prive = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionPriveOff(){
			this.edition_prive = false;
		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionPriveSave(){
			this.commentaire_prive = this.editor_commentaire_prive.getHTML()
			this.edition_prive     = false;
			this.saveCommentaire(true)
		},




		canEdit(champ){
			return true
		},

		//AS--> Supprime le commentaire en cours
		reset(prive){
			let self = this
			this.$dialog.confirm(self.$t('parcelle_commentaire_suppression'), { loader: true })
				.then(dialog => {
					dialog.close();
					if(prive){
						self.commentaire_prive = ''
						this.editor_commentaire_prive.setContent('')
						self.saveCommentaire(true)
					}else{
						self.commentaire = ''
						this.editor_commentaire.setContent('')
						self.saveCommentaire()
					}
				});
		},

		//AS--> Sauvegarde le commentaire
		saveCommentaire(prive){
			let self = this;
			let options = prive ? {comment_prive: this.commentaire_prive} : {comment: this.commentaire}
			axios.put('api=inex/views/{view_code}/parcelles/{parcelle_id}/comment', options, {'headers' : {'Content-Type': 'application/json'}})
    			.then(function (response) {
    				console.log(response);
					self.sauvegarde = 'succes'
					if(self.indexTimeout) clearTimeout(self.indexTimeout)
					self.indexTimeout = setTimeout(function(){
						self.sauvegarde = false;
					},2000)

    			}).catch(function (error) {
					self.sauvegarde = 'echec'
					if(self.indexTimeout) clearTimeout(self.indexTimeout)
					self.indexTimeout = setTimeout(function(){
						self.sauvegarde = false;
					},2000)

    			})
		},
	},
	computed:{
		
	},
	components:{
		EditorContent,
		EditorMenuBar
	}
}