
export default {
	data() {
		return {
			company: this.$store.state.company.company,
			marches_ids: [],
			edition: false,
			voir_plus: false
		}
	},
	created(){
		//AS--> Force le mode édition à la création si on est en mode 'monentreprise'
		if(this.canEdit && this.$store.state.ui.content == 'monentreprise') this.editionOn();
	},
	watch:{
		"$store.state.company.company"(newValue, oldValue){
			this.company = this.$store.state.company.company
		}
	},
	methods:{
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.marches_ids = _.clone(this.company.complement.marches_ids);
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},

		switchValue(marcheId){
			if(this.marches_ids.includes(marcheId)){
				this.marches_ids = _.without(this.marches_ids, marcheId);
			}else{
				this.marches_ids.push(marcheId);
			}

		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(){
			let self = this;

			this.$store.dispatch('entreprise_modifie_champ', {
				id: this.company.id,
				champ: 'marches_ids',
				valeur: this.marches_ids
			})

			this.edition = false;
		}
	},
	computed:{
		canEdit(){
			return this.$store.getters.userCanEditCompany('marches', this.company.id)
		},
		getAffiches(){
			let self = this;
			let marches = [];
			this.$store.state.ui.marches.forEach(function(marche){
				if(self.company.complement.marches_ids.includes(marche.id) && (marches.length < 5 || self.company.complement.marches_ids.length == 6)) marches.push(marche.nom);
			})
			return marches;
		},
		getMasques(){
			if(this.company.complement.marches_ids.length <= 6) return null;
			let self = this;
			let marches = [];
			let i = 0;
			this.$store.state.ui.marches.forEach(function(marche){
				if(self.company.complement.marches_ids.includes(marche.id)) i++;
				if(i > 5 && self.company.complement.marches_ids.includes(marche.id)) marches.push(marche.nom);
			})
			return marches;
		}
	}
}