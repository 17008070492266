import Champ from "./champ";
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { 
	HardBreak,
	Heading,
	BulletList,
	ListItem,
	Bold,
	Italic,
	History,
} from 'tiptap-extensions'

export default {
	mixins: [Champ],
	data() {
		return {
			editor: null
		}
	},
	created(){},
	mounted(){
		this.editor = new Editor({
			content: this.valeur,
			extensions: [
				new BulletList(),
				new HardBreak(),
				new Heading({levels: [4,5]}),
				new ListItem(),
				new Bold(),
				new Italic(),
				new History(),
			],
			onUpdate: () => {
				this.valeur = this.editor.getHTML()
			}
		})
	},
	methods: {},
	beforeDestroy() {
		this.editor.destroy()
	},
	components:{
		EditorContent,
		EditorMenuBar
	}
}