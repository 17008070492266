import axios from '../../common/axios.js'

export default {
	data() {
    return {
      edition: null,
      erreur: false,
      company: this.$store.state.company.company
    }
  },
  watch:{
    "$store.state.company.company"(newValue, oldValue){
      this.company = this.$store.state.company.company
    }
  },
  methods:{
    //AS--> Switch une variable d'activité de type boolean
    changeData(donnee_sectorielle){
      let self = this;

      let value = donnee_sectorielle.valeur;

      //AS--> Valeurs booléennes 
      if(donnee_sectorielle.code.slice(-5) == '_bool'){
        value = parseInt(value);

      //AS--> Valeurs numériques
      }else{
        if(typeof value != 'number'){

          //AS--> Vérifie que c'est un chiffre
          if(value !== null){
            value = value.replaceAll(',', '.');
            value = value.replaceAll(' ', '');
          }
          if(value !== null && !value.match(/^[0-9.]*$/g)){
            this.erreur = 'numeric_value_only';
            return;
          }

          //AS--> Si la valeur est vide, on vérifie s'il y avait une valeur avant ou non
          if(value == null || value.trim() == ''){
            let old = _.clone(self.company.complement[donnee_sectorielle.code])
            if(old != null && old.trim() != ''){
              self.erreur = 'saisir_valeur';
              return;
            }
          }

        }

      }

      this.$store.dispatch('entreprise_modifie_champ', {
        id: this.company.id,
        champ: donnee_sectorielle.code,
        valeur: value
      })

      this.edition = null;
    },
    edit(donnee_sectorielle){
      this.erreur = false;
      this.edition = donnee_sectorielle.code;
    },
    editAndFocus(donnee_sectorielle){
      this.edit(donnee_sectorielle);
      setTimeout(
        function(){
          let input = document.getElementById('donnee_sectorielle_'+donnee_sectorielle.code);
          if(input) input.focus();
        },
        20
      )
    }
  },
  computed:{
    //AS--> Liste des variables d'activité de l'entreprise qui ne sont pas modifiables
    donneesSectoriellesNonEditable(){
      let self = this;

      let donnees = [];
      _.forEach(this.$store.state.ui.sourcing.variables, function(val, index){
        if(
          index != 'nbr_salaries'
          && index != 'billing_data'
          && !val.editable_fo 
          && (
            (typeof self.company.complement[index] != 'undefined' && self.company.complement[index] !== null) 
            || (typeof self.company[index] != 'undefined' && self.company[index] != null)
          )
        ) {

          let valeur = '';
          if(typeof self.company.complement !== 'undefined' && typeof self.company.complement[index] !== 'undefined'){
            valeur = _.clone(self.company.complement[index]);
          }else if(typeof self.company[index] !== 'undefined'){
            valeur = _.clone(self.company[index]);
          }

          let _donnee = {
            'label' : val.label,
            'valeur' : valeur,
            'ordre' : val.ordre,
            'code' : index
          }
          donnees.push(_donnee);
        }
      });
      donnees = _.orderBy(donnees, ['ordre']);

      return donnees;
    },
    //AS--> Liste des variables d'activité de l'entreprise qui sont modifiables + celles qui sont modifiables mais que l'entreprise ne possède pas
    donneesSectoriellesEditable(){
      let self = this;

      let donnees = [];
      _.forEach(this.$store.state.ui.sourcing.variables, function(val, index){
        if(val.editable_fo){

          let valeur = null;
          if(typeof self.company.complement !== 'undefined' && typeof self.company.complement[index] !== 'undefined'){
            valeur = _.clone(self.company.complement[index]);
          }else if(typeof self.company[index] !== 'undefined'){
            valeur = _.clone(self.company[index]);
          }

          let _donnee = {
            'label' : val.label,
            'valeur' : valeur,
            'ordre' : val.ordre,
            'code' : index
          }
          if(_donnee.valeur === null && index.slice(-5) == '_bool') _donnee.valeur = -1;
          
          donnees.push(_donnee);
        }
      });
      
      donnees = _.orderBy(donnees, ['ordre']);

      return donnees;
    }
  },
}