
export default {
	data() {
		return {
			company: this.$store.state.company.company,
			edition: false,
			voir_plus: false
		}
	},
	created(){
		//AS--> Force le mode édition à la création si on est en mode 'monentreprise'
		if(this.canEdit && this.$store.state.ui.content == 'monentreprise') this.editionOn();
	},
	methods:{
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(){
			// let self = this;

			// this.$store.dispatch('entreprise_modifie_champ', {
			// 	id: this.company.id,
			// 	champ: 'competences',
			// 	valeur: this.editor.getHTML()
			// })

			// this.edition = false;
		}
	},
	computed:{
		canEdit(){
			return this.$store.getters.userCanEditCompany('competences', this.company.id)
		},
		getAffiches(){
			let self = this;
			let competences = [];
			this.$store.state.ui.competences.forEach(function(competence){
				if(self.company.complement.competences_ids.includes(competence.id) && (competences.length < 3 || self.company.complement.competences_ids.length == 4)) competences.push(competence);
			})
			return competences;
		},
		getMasques(){
			if(this.company.complement.competences_ids.length <= 4) return null;
			let self = this;
			let competences = [];
			let i = 0;
			this.$store.state.ui.competences.forEach(function(competence){
				if(self.company.complement.competences_ids.includes(competence.id)) i++;
				if(i > 3 && self.company.complement.competences_ids.includes(competence.id)) competences.push(competence);
			})
			return competences;
		}
	},
}