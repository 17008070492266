import _ from 'lodash'
import CalculsDechets from '@/common/mixins/CalculsDechets.js'
import Loading from './loading_inex.vue'
import axios from 'axios'

export default {
	mixins: [CalculsDechets],
	data() {
		return {
			loading: false,
			sent: false,
		}
	},
	props: {
		entreprise_identifiant: {
			type: String,
			default: 0
		},
		matiere_id: {
			type: Number,
			default: 0
		},
		champ: {
			type: String,
			default: 0
		},
		quantite: {
			type: Number,
			default: 0
		},
		unite: {
			type: String,
			default: ''
		},
		nbr_digit: {
			type: Number,
			default: null
		}
	},
	created(){
		
	},
	methods: {
		reset(){
			this.loading = false
			this.sent    = false
		},
		sendToInex(evaluation){
			let self = this
			this.loading = true

			let data = {
				"company_id" : this.entreprise_identifiant,
				"material"   : this.matiere_id,
				"field"      : this.champ,
				"value"      : this.quantite,
				"review"     : evaluation
			}
			axios.post('api=inex/users/{user_email}/feedbacks/{view_code}', data)
			.then(function(response){
				console.log(response.data)
			})
			.catch(function(error){
				console.log(error)
			})
			.finally(function(){
				self.sent = true

				setTimeout(function(){
					self.reset()
				},3000)
			})

		}
	},
	computed:{
		getQtyAffiche(){
			return this.numberFormatQty(this.quantite, this.unite, true, this.nbr_digit)
		}
	},
	components:{
		Loading
	}
}