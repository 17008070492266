import Champ from "./champ";

export default {
	mixins: [Champ],
	created(){
		if(this.debugs.edition_matiere){
			console.log('=> '+this.i18n(this.params.label)+ ' ('+this.params.field+')', 'ChampSelect', _.cloneDeep(this.params.options), this.valeurs)
		}
	},
	methods: {},
	computed: {
		valeurs(){
			let self = this;
			let options = [];

			if(this.params.options){
				if(this.params.options.var){
					//AS--> Cas d'un select à monter sur la base d'un objet existant sur store.ui
					if(this.$store.state.ui[this.params.options.var]){
						_.forEach(this.$store.state.ui[this.params.options.var], function(option){
							options.push({
								val: option[self.params.options.value],
								name: option[self.params.options.name]
							});
						})
					
					//AS--> Cas d'un select à monter sur la base d'une nomenclature
					}else if(this.$store.getters.nomenclatureExist(this.params.options.var)){
						options = this.$store.getters.getNomenclatureList(this.params.options.var, this.$store.state.ui.langue);
					}

				//AS--> Cas d'un select à monter sur la base d'un objet fourni dans le yaml
				}else if(typeof this.params.options == 'object'){
					_.forEach(this.params.options, function(option, name){
						options.push({
							val: name,
							name: typeof option == 'object' ? self.i18n(option) : option
						});
					})

				}
			}else{
				options = this.$store.getters.getNomenclatureList_ByType('waste_editor', this.params.field);
			}

			//AS--> Cas du choix de la matière principale
			if(this.params.field == 'material_id'){

				//AS--> Si le sourcing n'est pas en fonctionnement 'annee_timestamp', on n'autorise pas à choisir une matière déjà choisie
				if(!this.$store.state.ui.sourcing.annee_timestamp){

					//AS--> Liste des ids des déchets déjà paramétrés sur l'entreprise
					let dechets_ids = _.get(this.$store.state.company.company, 'complement.dechets', []).map(dechet => dechet.id)

					//AS--> On ne tient pas compte dans cette liste du déchet en cours d'édition
					dechets_ids = _.without(dechets_ids, _.get(this.$store.state.company, 'edition_matiere.id'))

					//AS--> Retire de la liste des déchets disponibles les déchets déjà paramétrés (sauf celui en cours)
					_.remove(options, option => dechets_ids.includes(option.val))
				}
			}

			//AS--> Classe par ordre alphabétique des noms de matières
			options = _.orderBy(options, 'name', 'asc')

			return options;
		}
	}
}