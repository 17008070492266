import _ from 'lodash'
import StatusesSelector from '../ui/statuses_selector.vue'

export default {
	data() {
		return {
			
		}
	},
	props: ['element'],
	mounted(){
		
	},
	methods:{
		statusChange(status){
			this.element.value = status
		}
	},
	computed:{
		entreprise(){
			return {
				identifiant    : this.$store.state.company.company.id,
				current_status : this.element.value
			}
		}
	},
	components:{
		StatusesSelector
	}
}