import _ from 'lodash'

export default {
	data() {
    return {
      company: this.$store.state.company.company
    }
  },
  computed:{
    getCapacites(){
      let self = this;
      if(!this.company.complement.capacites) return [];
      let capacites = [];
      //AS--> Prend toutes les capacités définies dans le sourcing, dans l'ordre d'affichage
      self.$store.getters.getCapacitesIds.forEach(function(capacite_id_famille){
        self.company.complement.capacites.forEach(function(capacite){
          if(capacite.id == capacite_id_famille) capacites.push(capacite);
        })
      })
      return capacites;
    },
  },
  methods:{
    getHtml(value){
      return _.unescape(value);
    }
  }
}