import _ from 'lodash'
import Utils from '../utils.js';
import axios from '../axios.js'

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { 
	HardBreak,
	Heading,
	BulletList,
	ListItem,
	Bold,
	Italic,
	History,
} from 'tiptap-extensions'

export default {
	mixins: [Utils],
	props: ['element'],
	data() {
		return {
			edition: false,
			value: null,
			editor_commentaire_sourcing: new Editor({
				content: this.element.value || '',
				extensions: [
					new BulletList(),
					new HardBreak(),
					new Heading({levels: [4,5]}),
					new ListItem(),
					new Bold(),
					new Italic(),
					new History(),
				]
			})
		}
	},
	created(){
		this.value = this.element.value
		if(this.canEdit('commentaire_sourcing') && this.$store.state.ui.content == 'monentreprise') this.editionOn();
	},
	watch:{
		"$store.state.company.company"(newValue, oldValue){
			this.company = this.$store.state.company.company
		}
	},
	methods:{
		//AS--> Ouvre l'éditeur
		editionOn(){
			this.editor_commentaire_sourcing.setContent(this.value || '')
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.edition = false;
		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(champ){
			let self = this;
			this.value = this['editor_'+champ].getHTML()
			this.edition = false
			this.postInexFieldData(this.element.field, this.value)
		},
		canEdit(champ){
			return this.element.editable
		}
	},
	computed:{},
	beforeDestroy() {
		// Always destroy your editor instance when it's no longer needed
		this.editor_commentaire_sourcing.destroy()
	},
	components: {
		EditorContent,
		EditorMenuBar
	}
}